import { GridColDef } from "@mui/x-data-grid";
import { Link as RouterLink, useAsyncValue, useRouteLoaderData } from "react-router-dom";
import {
  DataGridPremium,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icons,
  formatDollarAmountUsd,
  formatWalletAddress,
} from "@bakkt/bakkt-ui-components";
import { Box, SvgIcon, Unstable_Grid2 as Grid, Tooltip, IconButton, Snackbar } from "@mui/material";
import { PriceInfo, Wallet } from "../../services/openAPI/client";
import { CryptoTickerEnum } from "../../utils/CryptoIconsMap.ts";
import {
  checkWarmBalance,
  checkWarmWallet,
  convertPricingInfoArrayIntoMap,
  deriveWalletDetailsFromWallets,
  deriveWalletSummariesFromWallets,
  getSVGStringForTicker,
  sortWalletSummaryByAssetId,
  WalletSummary,
} from "../../utils/dataUtils.ts";
import React, { useState } from "react";
import { useRootContext } from "../../RootLayout";
import { isInitiator } from "../../utils/permissionsUtil";
import { tableHeader } from "./styles";
import QuantityDisplay from "../../components/quantityDisplay/QuantityDisplay.tsx";
import { AssetSVGIcon } from "../../components/customSVG/AssetSVGIcon.tsx";

export function WalletsGrid() {
  const [open, setOpen] = useState(false);

  const wallets: any = useAsyncValue() as Wallet[];
  const { priceFeed, assets } = useRootContext();
  const { userInfo }: any = useRouteLoaderData("root");

  const pricingInfoMap = convertPricingInfoArrayIntoMap(priceFeed, assets);
  const walletSummaries = deriveWalletSummariesFromWallets(wallets, priceFeed, assets);
  const walletDetails = deriveWalletDetailsFromWallets(wallets);
  const { selectedOrg } = useRootContext();
  const isWarmBalance = checkWarmBalance(wallets, priceFeed as PriceInfo[], assets);

  const linkTextSx = {
    textDecoration: "underline",
  };

  const handleCopyAddress = (address: string) => {
    setOpen(true);
    navigator.clipboard.writeText(address);
  };

  function calculateFlex() {
    return checkWarmBalance(wallets, priceFeed, assets) ? 0.9 : 1.5;
  }

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "WALLET NAME",
      width: 145,
      renderCell: (params) => (
        <>
          <RouterLink to={`wallet/${params.row.walletId}`}>
            <Box component="span" sx={linkTextSx}>
              {params.value}
            </Box>
          </RouterLink>
        </>
      ),
    },
    {
      field: "temperature",
      headerName: "TEMPERATURE",
      width: 130,
      editable: false,
      valueGetter: (params: any) => {
        return params.row.temperature;
      },
    },
    {
      field: "address",
      headerName: "WALLET ADDRESS",
      width: 180,
      editable: false,
      renderCell: (params) =>
        params.value && (
          <>
            <IconButton
              aria-label="copy"
              onClick={() => handleCopyAddress(params.value)}
              disableRipple
              disableFocusRipple
            >
              <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={{ width: 15, height: 15 }} />
            </IconButton>
            <Tooltip title={params.value}>
              <span>{formatWalletAddress(params.value)}</span>
            </Tooltip>
          </>
        ),
    },
    {
      field: "walletId",
      headerName: "ID",
      width: 70,
      editable: false,
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      description: "",
      sortable: false,
      width: 200,
    },
    {
      field: "network",
      headerName: "NETWORK",
      sortable: false,
      width: 140,
    },
    {
      field: "quantity",
      headerName: "",
      description: "",
      sortable: false,
      headerAlign: "right",
      align: "right",
      flex: calculateFlex(),
      renderCell: (params: any) => {
        if (params.rowNode.type === "group") {
          return "";
        } else {
          return (
            <>
              <QuantityDisplay quantity={params.value} ticker={params.row.assetTicker} />
            </>
          );
        }
      },
    },
    {
      // field is required, this doesn't actually match any field in the response, but valueGetter will display what we want
      field: "usdBalance",
      headerName: "",
      description: "",
      sortable: false,
      headerAlign: "right",
      align: "right",
      width: 200,
      flex: 1,
      valueGetter: (params: any) => {
        if (params.rowNode.type === "group") {
          return "";
        } else {
          const priceInfo = pricingInfoMap[params.row.assetTicker];
          const balance = params.row.quantity * (priceInfo.bidPrice || 0);
          return formatDollarAmountUsd(balance);
        }
      },
    },
    {
      // field is required, this doesn't actually match any field in the response, but valueGetter will display what we want
      field: "actions",
      headerName: "",
      description: "",
      sortable: false,
      headerAlign: "right",
      align: "right",
      flex: 1,
      renderCell: (params) => (
        <Grid justifyContent="space-between">
          <RouterLink to={`deposit/${params.row.walletId}`}>
            <Box component="span" sx={linkTextSx}>
              Deposit
            </Box>
          </RouterLink>{" "}
          {isInitiator(userInfo, Number(selectedOrg.id)) && (
            <>
              |{" "}
              <RouterLink to={`${location.pathname}/withdraw/${params.row.walletId}`}>
                <Box component="span" sx={linkTextSx}>
                  Withdraw
                </Box>
              </RouterLink>
            </>
          )}
        </Grid>
      ),
    },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        id: false,
        temperature: checkWarmBalance(wallets, priceFeed, assets),
      },
    },
  };

  function addAccountButton(event: any) {
    event.stopPropagation();
    return;
  }

  //TODO: Refactor styles into modules.css files
  const cryptoIconStyle = {
    position: "absolute",
    marginRight: "8px",
    top: "28px",
  };
  const cryptoNameStyle = {
    marginLeft: "34px",
  };
  const formatOptions: Intl.NumberFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 6,
  };
  return (
    <>
      <Grid container sx={{ flexGrow: 1, marginBottom: "20px", paddingLeft: "32px" }}>
        <Grid xs={5}>
          <Typography variant="overline" data-testid="label" sx={[tableHeader, { marginLeft: "-32px" }]}>
            ASSET
          </Typography>
        </Grid>
        <Grid xs={7} sx={{ flexGrow: 1, paddingRight: "0" }}>
          <>
            <Grid container sx={{ flexGrow: 1 }}>
              <Grid xs={3} sx={{ textAlign: "right" }}>
                <Typography variant="overline" data-testid="label" sx={tableHeader}>
                  MARKET PRICE
                </Typography>
              </Grid>
              <Grid xs={3} sx={{ textAlign: "right" }}>
                <Typography variant="overline" data-testid="label" sx={tableHeader}>
                  QUANTITY
                </Typography>
              </Grid>
              <Grid xs={3} sx={{ textAlign: "right" }}>
                <Typography variant="overline" data-testid="label" sx={tableHeader}>
                  BALANCE
                </Typography>
              </Grid>
              <Grid xs={3} sx={{ textAlign: "right" }}>
                <Typography variant="overline" data-testid="label" sx={tableHeader}>
                  ACTIONS
                </Typography>
              </Grid>
            </Grid>
          </>
        </Grid>
      </Grid>

      {/*build the nested datagrid only when you have wallets of the current selected org*/}
      {wallets &&
        wallets.length > 0 &&
        wallets[0].organizationId === selectedOrg.id &&
        walletSummaries &&
        sortWalletSummaryByAssetId(walletSummaries, assets).map((walletSummary: WalletSummary, index: number) => (
          <Box key={index}>
            <Accordion>
              <AccordionSummary
                aria-controls="panel-content"
                id={walletSummary.assetName}
                data-testid={walletSummary.assetName}
                sx={{ pointerEvents: "none", paddingLeft: "0" }}
                expandIcon={<SvgIcon component={Icons.ChevronDownIcon} sx={{ pointerEvents: "auto" }} />}
              >
                <Grid container sx={{ flexGrow: 1, marginTop: "10px", marginBottom: "5px" }}>
                  <Grid xs={5}>
                    <SvgIcon
                      component={() =>
                        AssetSVGIcon({
                          svgString: getSVGStringForTicker(assets, walletSummary.assetTicker),
                          title: walletSummary.assetTicker,
                          sx: cryptoIconStyle,
                        })
                      }
                      inheritViewBox
                    />
                    <Typography variant="h5" sx={{ fontWeight: 500 }}>
                      <span style={cryptoNameStyle}>{walletSummary.assetName}</span>
                    </Typography>
                    <Typography variant="body2" sx={cryptoNameStyle}>
                      {walletSummary.assetTicker}
                    </Typography>
                  </Grid>
                  <Grid xs={7}>
                    <Box>
                      <Grid container sx={{ flexGrow: 1 }}>
                        <Grid xs={3} sx={{ textAlign: "right" }}>
                          <Typography sx={{ mb: "-3px" }}>
                            {walletSummary.assetTicker === CryptoTickerEnum.SHIB
                              ? `$${walletSummary.marketPrice.toLocaleString(undefined, formatOptions)}`
                              : formatDollarAmountUsd(walletSummary.marketPrice)}
                          </Typography>
                          {/*TODO: Coming back in Phase 2*/}
                          {/*<Typography variant="body2" sx={{ color: "green" }}>*/}
                          {/*  {walletSummary.percentChange} Today*/}
                          {/*</Typography>*/}
                        </Grid>
                        <Grid xs={3} sx={{ textAlign: "right" }}>
                          <Typography sx={{ pointerEvents: "auto" }}>
                            <QuantityDisplay quantity={walletSummary.quantity} ticker={walletSummary.assetTicker} />
                          </Typography>
                        </Grid>
                        <Grid xs={3} sx={{ textAlign: "right" }}>
                          <Typography>{formatDollarAmountUsd(walletSummary.balance)}</Typography>
                        </Grid>
                        <Grid xs={3} sx={{ textAlign: "right", pointerEvents: "auto" }}>
                          <RouterLink
                            to={`deposit/`}
                            state={{
                              ticker: walletSummary.assetTicker,
                            }}
                          >
                            <Box component="span" sx={linkTextSx}>
                              Deposit
                            </Box>
                          </RouterLink>{" "}
                          {isInitiator(userInfo, Number(selectedOrg.id)) && (
                            <>
                              |{" "}
                              <RouterLink
                                to={`${location.pathname}/withdraw/`}
                                state={{
                                  ticker: walletSummary.assetTicker,
                                }}
                              >
                                <Box component="span" sx={linkTextSx}>
                                  Withdraw
                                </Box>
                              </RouterLink>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails data-testid="dashboardWalletDetails">
                {walletSummary.assetTicker && (
                  <DataGridPremium
                    autoHeight
                    {...{
                      columns,
                      initialState,
                      rows: walletDetails[walletSummary.assetTicker].filter((wallet) =>
                        checkWarmWallet(isWarmBalance, wallet.temperature as string),
                      ),
                    }}
                    disableRowSelectionOnClick
                    initialState={initialState}
                    getRowId={(row) => row.walletId}
                  />
                )}

                <Snackbar
                  open={open}
                  onClose={() => setOpen(false)}
                  autoHideDuration={2000}
                  message="Address copied"
                  anchorOrigin={{ horizontal: "center", vertical: "top" }}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
    </>
  );
}
