import { Typography, color } from "@bakkt/bakkt-ui-components";
import { Wallet, WalletTransactionTypeEnum } from "../../../services/openAPI/client";
import { WalletBalanceAvailableOnly } from "../balances/WalletBalanceAvailableOnly";

interface SelectCollateralWalletBalanceViewProps {
  selectedWallet: Wallet | null;
  selectedWalletId: string;
  selectedWalletName?: string;
  collateralAction?: string;
}

function SelectCollateralWalletBalanceView({
  selectedWalletId,
  selectedWalletName,
  selectedWallet,
  collateralAction,
}: SelectCollateralWalletBalanceViewProps) {
  const bodyHeadingSx = {
    color: color.text.secondary,
    fontWeight: "bold",
  };
  return (
    <>
      {selectedWallet ? (
        <>
          {selectedWalletId && (
            <WalletBalanceAvailableOnly
              walletId={selectedWalletId.toString() as string}
              walletName={selectedWalletName ?? selectedWallet?.name}
            />
          )}
        </>
      ) : (
        <>
          <Typography variant="body2" sx={bodyHeadingSx}>
            {collateralAction === WalletTransactionTypeEnum.RemoveCollateralWithdraw
              ? "DESTINATION WALLET AMOUNT AVAILABLE"
              : "SOURCE WALLET AMOUNT AVAILABLE"}
          </Typography>
          <Typography variant="h5">Wallet Not Selected</Typography>
          <Typography variant="subtitle1">$0.00</Typography>
        </>
      )}
    </>
  );
}

export default SelectCollateralWalletBalanceView;
